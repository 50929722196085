import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/analytics';
import { useFirestore } from 'react-redux-firebase';

const { arrayRemove, arrayUnion } = firebase.firestore.FieldValue;

if (!firebase.apps.length) {
  // Initialize Firebase
  const firebaseConfig = {
    apiKey: "AIzaSyBGc8AqyIrXVMpqcLNnooayoLqbk4fSr-E",
    authDomain: "techrepair-glass.firebaseapp.com",
    projectId: "techrepair-glass",
    storageBucket: "techrepair-glass.appspot.com",
    messagingSenderId: "772141911048",
    appId: "1:772141911048:web:c34b5cda2e34825a7555bd"
  };

  firebase.initializeApp(firebaseConfig);
  firebase.firestore();
  if (window.location.href.includes('http://localhost')) {
    firebase.auth().useEmulator('http://localhost:5002');
    firebase.functions().useEmulator('localhost', 5001);
    firebase.firestore().useEmulator('localhost', 5000);
  }
}

export default firebase;

export function useAssignJob() {
  const firestore = useFirestore();

  return [
    async (regionId, orderId, oldTechId, newTechId) => {
      newTechId = newTechId === 'Unassigned' ? null : newTechId;
      oldTechId = oldTechId === 'Unassigned' ? null : oldTechId;

      const batch = firestore.batch();
      const jobRef = firestore
        .collection(`/regions/${regionId}/jobs`)
        .doc(orderId);

      if (oldTechId) {
        const oldTechRef = firestore
          .collection(`/regions/${regionId}/techs`)
          .doc(oldTechId);
        batch.update(oldTechRef, {
          jobs: arrayRemove(orderId)
        });
      }

      if (newTechId) {
        const newTechRef = firestore
          .collection(`/regions/${regionId}/techs`)
          .doc(newTechId);
        batch.update(newTechRef, {
          jobs: arrayUnion(orderId)
        });
      }

      batch.update(jobRef, {
        assignee: newTechId
      });

      await batch.commit();
    }
  ];
}

export function useDeleteOrder(regionId, { onComplete }) {
  const firestore = useFirestore();

  return [
    async orderId => {
      const batch = firestore.batch();
      const orderRef = firestore
        .collection(`/regions/${regionId}/jobs`)
        .doc(orderId);

      const snap = await firestore
        .collection(`/regions/${regionId}/techs`)
        .where('jobs', 'array-contains', orderId)
        .get();
      snap.forEach(doc => {
        console.log('removing from doc');
        batch.update(doc.ref, {
          jobs: arrayRemove(orderId)
        });
      });

      batch.delete(orderRef);

      await batch.commit();

      if (onComplete) {
        onComplete();
      }
    }
  ];
}
